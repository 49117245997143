// DateTimeFormatOptions type is not being exported in lib.es5, so
// have to do a hack to make the constant typescript-friendly
export const GENERIC_DATE_TIME_OPTIONS = {
  year: 'numeric' as 'numeric',
  month: 'long' as 'long',
  day: 'numeric' as 'numeric',
  hour: '2-digit' as '2-digit',
  minute: '2-digit' as '2-digit',
  hourCycle: 'h23' as 'h23',
}

export const LOCAL_STORAGE_VARIABLES = {
  ACCESS_TOKEN: 'accessToken',
  AUTH_TOKEN: 'authToken',
  REFRESH_TOKEN: 'refreshToken',
}

export enum Sizes {
  ExtraLarge = 'extraLarge',
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
  ExtraSmall = 'extraSmall',
}

export type LoginOptionType = {
  code: string
  text: string
  icon?: string
}

export const EMAIL_OTP_CODE = 'EMAIL_OTP_CODE'
export const EID_CODE = 'EID' // onfido

const filterGlobal = (option: LoginOptionType) => option.code !== EMAIL_OTP_CODE
const filterEID = (option: LoginOptionType) => option.code !== EID_CODE

export const loginOptions: LoginOptionType[] = [
  {
    text: 'Finland',
    code: 'FI',
    icon: '\uD83C\uDDEB\uD83C\uDDEE',
  },
  {
    text: 'Sweden',
    code: 'SE',
    icon: '\uD83C\uDDF8\uD83C\uDDEA',
  },
  {
    text: 'Estonia',
    code: 'EE',
    icon: '\uD83C\uDDEA\uD83C\uDDEA',
  },
  {
    text: 'Latvia',
    code: 'LV',
    icon: '\uD83C\uDDF1\uD83C\uDDFB',
  },
  {
    text: 'Lithuania',
    code: 'LT',
    icon: '\uD83C\uDDF1\uD83C\uDDF9',
  },
  {
    text: 'Denmark',
    code: 'DK',
    icon: '\uD83C\uDDE9\uD83C\uDDF0',
  },
  {
    text: 'Email OTP',
    code: EMAIL_OTP_CODE,
    icon: '\uD83C\uDF0E',
  },
  {
    text: 'Other',
    code: EID_CODE,
    icon: '\uD83C\uDF0E',
  },
]

export const supportedOrganizationCountries: LoginOptionType[] = [
  {
    text: 'Finland',
    code: 'FI',
    icon: '\uD83C\uDDEB\uD83C\uDDEE',
  },
  {
    text: 'Sweden',
    code: 'SE',
    icon: '\uD83C\uDDF8\uD83C\uDDEA',
  },
  {
    text: 'Estonia',
    code: 'EE',
    icon: '\uD83C\uDDEA\uD83C\uDDEA',
  },
  {
    text: 'Latvia',
    code: 'LV',
    icon: '\uD83C\uDDF1\uD83C\uDDFB',
  },
  {
    text: 'Lithuania',
    code: 'LT',
    icon: '\uD83C\uDDF1\uD83C\uDDF9',
  },
  {
    text: 'Denmark',
    code: 'DK',
    icon: '\uD83C\uDDE9\uD83C\uDDF0',
  },
  {
    text: 'Norway',
    code: 'NO',
    icon: '\ud83c\uddf3\ud83c\uddf4',
  },
]

export const loginOptionsWithoutGlobal = loginOptions.filter(filterGlobal)
export const countriesOptions = loginOptions.filter(filterEID).filter(filterGlobal)
export const loginOptionsWithoutEID = loginOptions.filter(filterEID)

export enum VATPatterns {
  SE = '^SE\\d{10}01$',
  EE = '^EE\\d{9}$',
  LV = '^LV\\d{11}$',
  LT = '^LT\\d{9}(\\d{3})?$',
  NO = '^\\d{9}MVA$',
  DK = '^DK\\d{8}$',
}

export enum ComponentVariants {
  Primary = 'primary',
  DisabledPrimary = 'disabledPrimary',
  Secondary = 'secondary',
  Borderless = 'borderless',
  NegativeSecondary = 'negativeSecondary',
  DisabledSecondary = 'disabledSecondary',
  Light = 'light',
  Dark = 'Dark',
  Grey = 'Grey',
  BorderRed = 'borderRed',
  BorderGrey = 'borderGrey',
  BorderGradient = 'borderGradient',
  Gradient = 'gradient',
}

export const DEFAULT_STORY_SOURCE_CONFIG = {
  language: 'jsx',
  format: true,
  type: 'auto',
}

export enum ColorVariants {
  Error = 'error',
  Info = 'info',
  Special = 'special',
  Success = 'success',
  Warning = 'warning',
}

export enum ComponentStatus {
  Active = 'active',
  Inactive = 'inactive',
  Pending = 'pending',
}

export enum Alignments {
  Left = 'left',
  Center = 'center',
  Right = 'right',
  Between = 'between',
  Start = 'start',
  End = 'end',
}
